import { Button, Grid, Theme, Typography, useTheme } from '@mui/material';
import usePagination from '@mui/material/usePagination/usePagination';
import { Box, styled, SxProps } from '@mui/system';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React, { useState } from 'react';
import { LatestNewsCategoryPageQueryQuery } from '../../../graphql-types';
import { IconFilter } from '../../assets/icons';
import { ImageHouse } from '../../assets/images';
import { CAPTIONS_TEXT_COLOR } from '../../colors';
import { HeroSection } from '../../components';
import { NewsCard } from '../../components/news-card';
import { FlexBox } from '../../components/primitives/flex';
import { Width80Box } from '../../components/primitives/Width80Box';
import { disablePointerEvent, linkStyle } from '../../constants';

const List = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'flex',
  alignItems: 'center'
});

export interface LatestNewsViewProps {
  data: LatestNewsCategoryPageQueryQuery;
  pageContext: any;
  parentUrl: string;
}

const LatestNewsView = (props: LatestNewsViewProps) => {
  const theme: Theme = useTheme();
  const breakpoints = useBreakpoint();

  /************************ START STYLE **************************/
  const sxProps: { [className: string]: SxProps } = {
    wrapper: {
      [theme.breakpoints.down(1025)]: {
        px: '50px'
      },
      [theme.breakpoints.down('md')]: {
        mb: '80px',
        px: '20px'
      }
    },
    filterBtn: {
      borderRadius: '40px',
      boxShadow: 'none',
      paddingX: '28px',
      paddingY: '16px',
      width: 'fit-content',
      textTransform: 'none'
    },
    filterWrapper: { mb: '20px', flexDirection: 'column' },
    filterContainer: { overflowX: 'auto', mt: '20px', pb: '8px' },
    tag: {
      display: 'block',
      border: '2px solid' + theme.palette.primary.main,
      borderRadius: '100px',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontWeight: 500,
      marginRight: '12px',
      padding: '12px 26px',
      '&:hover': {
        bgcolor: 'primary.main',
        color: 'white'
      }
    },
    articlesWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: breakpoints.md ? 'center' : 'flex-start'
    },
    noPostContainer: {
      alignItems: 'center',
      height: '320px',
      justifyContent: 'center',
      width: '100%'
    },
    paginationBtn: {
      color: theme.palette.secondary.main,
      fontSize: '18px',
      fontWeight: 500,
      textTransform: 'none'
    },
    arrow: {
      fontFamily: 'Inter',
      fontWeight: 'normal',
      marginLeft: '8px'
    }
  };

  /************************ END STYLE **************************/
  const { data, parentUrl } = props;
  const { currentPage, numPages, tagName, tagId } = props.pageContext;
  const newsTags = data.allCraftGqlNewsTagsTag.nodes;
  const [filterByTopicButtonSwitch, setFilterByTopicButtonSwitch] = useState<boolean>(
    tagId ? true : false
  );

  const newsPosts = data.allCraftGqlLatestNewsDefaultEntry.edges;

  const firstPost = newsPosts.length > 0 ? newsPosts[0].node : null;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1 ? parentUrl : `${parentUrl}/` + (currentPage - 1).toString();
  const nextPage = `${parentUrl}/` + (currentPage + 1).toString();

  const { items } = usePagination({
    count: numPages,
    page: currentPage
  });

  const [selectedTag, setSelectedTag] = useState<string>(tagId);
  const handleFilterChange = (value: string) => {
    if (selectedTag === value) setSelectedTag(value);
    else setSelectedTag('');
  };

  return (
    <Box>
      <HeroSection
        bgImage={ImageHouse}
        bgImageStyle={{
          backgroundPosition: 'bottom'
        }}
        icon={null}
        title={
          tagName ? (
            `Post for ${tagName}`
          ) : (
            <div>
              Read our latest <br />
              news
            </div>
          )
        }
      />

      <Width80Box sx={sxProps.wrapper} mt={{ xs: '40px', sm: '40px', md: '40px', lg: '90px' }}>
        <FlexBox sx={sxProps.filterWrapper}>
          {/* <Badge badgeContent="" color="error"> */}
          <Button
            color="secondary"
            onClick={() => setFilterByTopicButtonSwitch(!filterByTopicButtonSwitch)}
            startIcon={<IconFilter />}
            sx={sxProps.filterBtn}
            variant="contained"
          >
            {filterByTopicButtonSwitch ? 'Hide filters' : 'Filter by topic'}
          </Button>
          {/* </Badge> */}

          <FlexBox mb={filterByTopicButtonSwitch && '20px'} sx={sxProps.filterContainer}>
            {filterByTopicButtonSwitch &&
              newsTags.map((tag) => (
                <Link
                  key={tag.slug}
                  to={`/latest-news/category/${tag.slug}`}
                  style={tag.id === tagId ? disablePointerEvent : linkStyle}
                >
                  <Typography
                    color={selectedTag === tag.id ? 'white' : 'secondary.main'}
                    bgcolor={selectedTag === tag.id ? 'primary.main' : 'transparent'}
                    sx={sxProps.tag}
                    variant="body1"
                    component="span"
                    onClick={() => handleFilterChange(tag.id)}
                  >
                    {tag.title}
                  </Typography>
                </Link>
              ))}
          </FlexBox>
        </FlexBox>

        {newsPosts.length > 0 ? (
          <>
            <Box sx={sxProps.articlesWrapper}>
              <Grid container columnSpacing={{ xs: '10px', sm: '60px' }}>
                {newsPosts.map((post, index) => {
                  const newsPost = post.node;

                  return index === 0 && currentPage === 1 ? (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={12}
                      key={newsPost.id}
                      mb={{ xs: '24px', md: '60px' }}
                    >
                      <NewsCard
                        date={Date.parse(firstPost.postDate)}
                        slug={firstPost.slug}
                        filters={firstPost.newsTags.map((tag) => {
                          const newTag = tag as { id: string; title?: string };
                          return newTag.title;
                        })}
                        imageLink={firstPost.heroBackgroundImage[0].url}
                        title={firstPost.title}
                        variant={breakpoints.md ? 'small' : 'big'}
                        customCardStyle={{ mx: 'auto' }}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={6} lg={4} key={newsPost.id}>
                      <NewsCard
                        date={Date.parse(newsPost.postDate)}
                        slug={newsPost.slug}
                        imageLink={newsPost.heroBackgroundImage[0].url}
                        title={newsPost.title}
                        filters={newsPost.newsTags.map((tag) => {
                          const newTag = tag as { id: string; title?: string };
                          return newTag.title;
                        })}
                        customCardStyle={{ mx: 'auto' }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Box>

            <Box mt="80px">
              <List>
                {items.map(({ page, type, selected, ...item }, index) => {
                  let children = null;

                  if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                    children = '…';
                  } else if (type === 'page') {
                    children = (
                      <Link
                        to={`${parentUrl}/${page !== 1 ? page : ''}`}
                        style={currentPage === page ? disablePointerEvent : linkStyle}
                      >
                        <Button
                          sx={{
                            color: selected ? theme.palette.secondary.main : CAPTIONS_TEXT_COLOR,
                            fontSize: '18px',
                            minWidth: breakpoints.md && '32px'
                          }}
                          {...item}
                        >
                          {page}
                        </Button>
                      </Link>
                    );
                  } else if (type === 'next') {
                    return (
                      <li key={index} style={{ marginLeft: 'auto' }}>
                        <Link to={nextPage} style={isLast ? disablePointerEvent : linkStyle}>
                          <Button {...item} sx={sxProps.paginationBtn}>
                            {!breakpoints.md && type}
                            <Typography component="span" sx={sxProps.arrow}>
                              →
                            </Typography>
                          </Button>
                        </Link>
                      </li>
                    );
                  } else {
                    return (
                      <li key={index}>
                        <Link to={prevPage} style={isFirst ? disablePointerEvent : linkStyle}>
                          <Button {...item} sx={sxProps.paginationBtn}>
                            <Typography component="span" sx={sxProps.arrow}>
                              ←
                            </Typography>
                            {!breakpoints.md && type}
                          </Button>
                        </Link>
                      </li>
                    );
                  }
                  return breakpoints.md ? (
                    (index === 1 || index == items.length - 2 || selected) && (
                      <li key={index} style={{ marginLeft: index === 1 && 'auto' }}>
                        {children}
                      </li>
                    )
                  ) : (
                    <li key={index} style={{ marginLeft: index === 1 && 'auto' }}>
                      {children}
                    </li>
                  );
                })}
              </List>
            </Box>
          </>
        ) : (
          <FlexBox sx={sxProps.noPostContainer}>
            <Typography>No Post for "{tagName}"</Typography>
          </FlexBox>
        )}
      </Width80Box>
    </Box>
  );
};

export default LatestNewsView;
