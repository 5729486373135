import { graphql } from 'gatsby';
import React from 'react';
import { LatestNewsPageQueryQuery } from '../../graphql-types';
import Layout from '../components/layout';
import LatestNewsView from './views/latest-news-view';

export interface LatestNewsPageProps {
  data: LatestNewsPageQueryQuery;
  pageContext: any;
}

const LatestNewsPage = (props: LatestNewsPageProps) => {
  const { data, pageContext } = props;
  const parentUrl = `/latest-news`;

  return <LatestNewsView data={data} parentUrl={parentUrl} pageContext={pageContext} />;
};

export const query = graphql`
  query LatestNewsPageQuery($skip: Int!, $limit: Int!) {
    allCraftGqlNewsTagsTag {
      nodes {
        title
        slug
        id
      }
    }
    allCraftGqlLatestNewsDefaultEntry(
      limit: $limit
      skip: $skip
      sort: { fields: postDate, order: DESC }
    ) {
      edges {
        node {
          id
          slug
          heroBackgroundImage {
            url
          }
          title
          postDate
          newsTags {
            ... on CraftGQL_newsTags_Tag {
              id
              title
            }
          }
        }
      }
    }
  }
`;

LatestNewsPage.Layout = Layout;
export default LatestNewsPage;
